<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  /deep/.el-form-item__content {
    line-height: 0 !important;
  }
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 24%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .el-form-item {
    margin-bottom: 10px;
    /deep/.el-form-item__content {
      display: flex;
    }
  }

  .icon-add {
    width: 30px;
    height: 30px;
    position: absolute;
    // top: 0;
    right: 0;
    cursor: pointer;
  }
  .icon-delete {
    width: 27px;
    height: 27px;
    position: absolute;
    right: 2.5rem;
    cursor: pointer;
  }
}
.item-row-block {
  display: flex;
  width: 100%;

  .item-row-title {
    white-space: nowrap;
    margin-right: 10px;
    min-width: 100px;
  }
}

.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 190px;
  margin-left: 0;
}
.dialogContent::-webkit-scrollbar {
  width: 12px;
}
.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}
.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="病变信息" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <span>病变部位</span>
          <el-select
            v-model="tumorTableInfo.lesionSite"
            filterable
            default-first-option
            allow-create
            size="mini"
            :clearable="true"
            @keydown.native="handleKeydown"
          >
            <el-option
              v-for="item in locationLesionList"
              :key="item.id"
              :label="item.comboValue"
              :value="item.comboValue"
            ></el-option>
          </el-select>
        </div>
        <div class="item-row">
          <span>本次治疗</span>
          <el-checkbox-group v-model="handlingOpinion" @change="handleHandlingOpinion">
            <template v-if="handlingOpinionList.length > 0">
              <el-checkbox
                v-for="ele in handlingOpinionList"
                :key="ele.id"
                :label="ele.comboValue"
                :disabled="isReadonly"
              >
                {{ ele.comboValue }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div v-show="universalDrugIsShow === true">
        <el-divider content-position="left">
          <span style="font-size: 18px; font-weight: bold">药物</span>
        </el-divider>
        <el-form>
          <div class="top-item-row">
            <template v-for="(item, index) in medicationTableData">
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>药物</span>
                <el-form-item :error="item?.drugNameErrorMessage">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.drugName"
                    @input="item.drugNameErrorMessage = ''"
                  />
                </el-form-item>
              </div>
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>剂量</span>
                <el-form-item :error="item?.drugDoseErrorMessage">
                  <cqt-input
                    style="width: 120px"
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.drugDose"
                    @input="item.drugDoseErrorMessage = ''"
                  />
                  <cqt-input
                    style="width: 60px"
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="单位"
                    type="text"
                    size="mini"
                    v-model="item.drugUnit"
                  />
                </el-form-item>

                <img
                  v-if="index === medicationTableData.length - 1"
                  src="../../../../assets/img/iconAdd.png"
                  class="icon-add"
                  alt=""
                  @click="addRow('药物')"
                />
                <img
                  v-if="index === medicationTableData.length - 1 && index !== 0"
                  src="../../../../assets/img/iconDelete.png"
                  class="icon-delete"
                  alt=""
                  @click="deleteRow('药物')"
                />
              </div>
            </template>
          </div>
        </el-form>
      </div>
      <div v-show="universalEquipmentIsShow === true">
        <el-divider content-position="left">
          <span style="font-size: 18px; font-weight: bold">耗材</span>
        </el-divider>
        <el-form>
          <div class="top-item-row">
            <template v-for="(item, index) in equipmentTableData">
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>耗材名称</span>
                <el-form-item :error="item?.equipmentNameErrorMessage">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.equipmentName"
                    @input="item.equipmentNameErrorMessage = ''"
                  />
                </el-form-item>
              </div>
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>耗材规格</span>
                <el-form-item>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.equipmentType"
                  />
                </el-form-item>
              </div>
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>耗材数量</span>
                <el-form-item :error="item?.numErrorMessage">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.num"
                    @input="item.numErrorMessage = ''"
                  />
                </el-form-item>
                <img
                  v-if="index === equipmentTableData.length - 1"
                  src="../../../../assets/img/iconAdd.png"
                  class="icon-add"
                  alt=""
                  @click="addRow('耗材')"
                />
                <img
                  v-if="index === equipmentTableData.length - 1 && index !== 0"
                  src="../../../../assets/img/iconDelete.png"
                  class="icon-delete"
                  alt=""
                  @click="deleteRow('耗材')"
                />
              </div>
              <div class="item-row"></div>
            </template>
          </div>
        </el-form>
        <div class="top-item-row">
          <el-table
            class="report-table"
            :data="tableData"
            ref="multipleTable"
            :header-cell-style="{ padding: 0 }"
            height="220px"
            highlight-current-row
            @row-click="_rowClick"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="index" align="center" width="50"></el-table-column>
            <el-table-column type="selection" align="center" width="45px"></el-table-column>
            <!-- <el-table-column
              property="surgeryDate"
              align="center"
              label="手术日期"
              show-overflow-tooltip
              width="180"
            ></el-table-column>
            <el-table-column
              property="surgeryBillNo"
              align="center"
              label="手术单号"
              show-overflow-tooltip
              width="120"
            ></el-table-column> -->
            <el-table-column
              property="equipmentName"
              align="center"
              label="耗材名称"
              show-overflow-tooltip
              width="220"
            ></el-table-column>
            <el-table-column
              property="equipmentType"
              align="center"
              label="耗材规格"
              show-overflow-tooltip
              width="120"
            ></el-table-column>
            <el-table-column
              property="productLocation"
              align="center"
              label="生产厂家"
              show-overflow-tooltip
              width="120"
            ></el-table-column>
            <el-table-column
              property="expiryDate"
              align="center"
              label="有效期"
              show-overflow-tooltip
              min-width="120"
            ></el-table-column>
            <el-table-column
              property="price"
              align="center"
              label="耗材价格"
              show-overflow-tooltip
              width="120"
            ></el-table-column>
            <el-table-column
              property="number"
              align="center"
              label="数量"
              show-overflow-tooltip
              width="60"
            ></el-table-column>
            <el-table-column
              property="cost"
              align="center"
              label="价格"
              show-overflow-tooltip
              width="60"
            ></el-table-column>
            <!-- <el-table-column
              property="applyDepotName"
              align="center"
              label="申请科室"
              show-overflow-tooltip
              width="80"
            ></el-table-column>
            <el-table-column
              property="brand"
              align="center"
              label="供应商名称"
              show-overflow-tooltip
              width="120"
            ></el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
// import CqtSelect from '../../../../components/cqt/CqtSelect.vue'

export default {
  name: 'UniversalInfoDialog',
  components: {
    CmDialogBorder,

    // CqtSelect,
    CqtInput
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    moduleInfo: {
      type: Object,
      default: () => ({})
    },
    locationLesionList: {
      type: Array,
      default: () => []
    },
    handlingOpinionList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      universalDrugIsShow: false,
      universalEquipmentIsShow: false,
      tumorTableInfo: {
        lesionSite: null, //
        handlingOpinion: null // 本次治疗
      },
      tableData: [],
      selectRowList: [],
      handlingOpinion: [],
      medicationTableData: [
        { drugName: '', drugDose: '', drugUnit: '' },
        { drugName: '', drugDose: '', drugUnit: '' }
      ],
      equipmentTableData: [{ equipmentName: '', equipmentType: '', num: '' }]
    }
  },
  watch: {
    moduleInfo: {
      handler(val) {
        if (val) {
          console.log(val.universalDrug)

          console.log(val.universalEquipment)

          if (val.universalDrug) {
            this.universalDrugIsShow = true
          } else {
            this.universalDrugIsShow = false
          }
          if (val.universalEquipment) {
            this.universalEquipmentIsShow = true
          } else {
            this.universalEquipmentIsShow = false
          }
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    addDetail() {
      let formData = new FormData()
      formData.append('type', 'locationLesion')
      formData.append('value', this.tumorTableInfo.lesionSite)

      this.$api
        .post(`/v1/webconsole/study/add/dictionary/${this.moduleInfo.moduleCode}`, formData)
        .then((res) => {
          this.$message.success(res.data.msg)
          this.$emit('upDictionaryDate')
        })
    },
    handleKeydown(event) {
      const keyCode = event.keyCode
      // Enter
      if (keyCode === 13) {
        this.addDetail()
        event.stopPropagation()
        return
      }
    },
    initData() {
      this.$api
        .get(`/v1/webconsole/equipment/operation/query/${this.$route.query.operationId}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.tableData = res.data.data
            if (this.tumorTableInfo.equipments?.length) {
              this.tableData.forEach((element) => {
                this.tumorTableInfo.equipments.forEach((ele) => {
                  if (ele.operationEquipmentId && element.uuid === ele.operationEquipmentId) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(element)
                    })
                  }
                })
              })
            }
          } else {
            this.tableData = []
          }
        })
    },

    Show(bloodVessel) {
      this.tumorTableInfo = {
        lesionSite: null, //
        handlingOpinion: null // 本次治疗
      }
      this.medicationTableData = this.$options.data().medicationTableData
      this.equipmentTableData = this.$options.data().equipmentTableData
      this.$refs.multipleTable.clearSelection()

      if (bloodVessel) this.tumorTableInfo = deepClone(bloodVessel)
      if (bloodVessel)
        this.handlingOpinion = deepClone(
          bloodVessel.handlingOpinion ? bloodVessel.handlingOpinion?.split('|') : []
        )
      this.isShow = true
      this.medicationTableData =
        this.tumorTableInfo?.drugs?.length > 0
          ? this.tumorTableInfo?.drugs
          : this.medicationTableData
      if (this.tumorTableInfo?.equipments?.length > 0) {
        console.log(this.tumorTableInfo?.equipments)
        let arr = []
        this.tumorTableInfo.equipments.forEach((ele) => {
          if (!ele.operationEquipmentId) {
            arr.push(ele)
          }
        })

        this.equipmentTableData = arr
      }

      this.initData()
    },
    Save() {
      try {
        let errors = []
        let equipmentErrors = []
        this.tumorTableInfo.drugs = []
        this.tumorTableInfo.equipments = []
        this.medicationTableData = this.medicationTableData?.map((element) => {
          element.drugDoseErrorMessage = ''
          element.drugNameErrorMessage = ''
          if (!element.drugDose && element.drugName) {
            element.drugDoseErrorMessage = '请输入药物剂量'
            errors.push('请输入药物名称和剂量')
          }
          if (!element.drugName && element.drugDose) {
            element.drugNameErrorMessage = '请输入药物'
            errors.push('请输入药物名称和剂量')
          }
          return element
        })
        this.equipmentTableData = this.equipmentTableData?.map((element) => {
          element.equipmentNameErrorMessage = ''
          element.numErrorMessage = ''
          if (!element.equipmentName && element.num) {
            element.equipmentNameErrorMessage = '请输入耗材名称'
            equipmentErrors.push('请输入耗材名称和数量')
          }
          if (!element.num && element.equipmentName) {
            element.numErrorMessage = '请输入数量'
            equipmentErrors.push('请输入耗材名称和数量')
          }
          return element
        })

        let arr = []
        let equipmentArr = []

        this.medicationTableData.forEach((element) => {
          if (element.drugName && element.drugDose) {
            arr.push({
              drugName: element.drugName,
              drugDose: element.drugDose,
              drugUnit: element.drugUnit
            })
          }
        })

        this.tumorTableInfo.equipments = deepClone(this.selectRowList)
        this.equipmentTableData.forEach((element) => {
          if (element.equipmentName && element.num) {
            equipmentArr.push({
              equipmentName: element.equipmentName,
              equipmentType: element.equipmentType,
              num: element.num
            })
          }
        })

        this.tumorTableInfo.drugs = arr
        this.tumorTableInfo.equipments = [...this.tumorTableInfo.equipments, ...equipmentArr]

        if (errors.length > 0) return
        if (equipmentErrors.length > 0) return
        console.log(this.medicationTableData)
        console.log(this.tumorTableInfo)
        this.$emit('save', this.tumorTableInfo)
        this.Close()
      } catch (err) {
        console.log(err)
      }
    },

    Close() {
      this.handlingOpinion = []
      this.isShow = false
    },

    _rowClick(row) {
      if (row) {
        this.$refs.multipleTable.toggleRowSelection(row)
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.selectRowList = val
      if (this.selectRowList.length > 0) {
        for (let i = 0; i < this.selectRowList.length; i++) {
          const ele = this.selectRowList[i]
          ele.useSuccess = '成功'
          ele.operationEquipmentId = ele.uuid
          ele.equipmentIndex = ele.equipmentId
          if (!ele.num) {
            ele.num = 1
          }
        }
      }
    },

    _dialogSave(val) {
      this.tableData.push(val)
    },

    handleHandlingOpinion(val) {
      this.tumorTableInfo.handlingOpinion = val.join('|')
    },
    // 添加
    addRow(type) {
      let newObj = {
        drugName: '',
        drugDose: '',
        drugUnit: ''
      }
      let newEquipmentObj = {
        equipmentName: '',
        equipmentType: '',
        num: ''
      }
      if (type == '药物') {
        this.medicationTableData.push(newObj)
      }
      if (type == '耗材') {
        this.equipmentTableData.push(newEquipmentObj)
      }
    },
    deleteRow(type) {
      if (type == '药物') {
        this.medicationTableData.splice(this.medicationTableData.length - 1, 1)
      }
      if (type == '耗材') {
        this.equipmentTableData.splice(this.equipmentTableData.length - 1, 1)
      }
    }
  },

  computed: {}
}
</script>
