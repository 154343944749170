<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">病变信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="universalInsert"
          :disabled="isReadonly"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="universalModify"
          :disabled="isReadonly"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="universalDelete"
          :disabled="isReadonly"
        >
          删除
        </el-button>
      </div>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="universalTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionSite" label="病变部位">
          <template slot-scope="scope">
            {{ scope.row.lesionSite?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
        <el-table-column prop="embolicDrugs" label="使用药物">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.drugs" :key="index">
              <span>{{ item.drugName + '，' }}</span>
              <span v-if="item.drugDose">{{ item.drugDose }}</span>
              <span v-if="item.drugUnit">{{ item.drugUnit }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="embolicDrugs" label="使用耗材">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.equipments" :key="index">
              <span>{{ item.equipmentName + ' ' }}</span>
              <span v-if="item.num">数量：{{ item.num }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="handlingOpinion" label="本次治疗">
          <template slot-scope="scope">
            {{ scope.row.handlingOpinion?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
      </el-table>
      <universal-info-dialog
        ref="UniversalInfoDialog"
        :isReadonly="isReadonly"
        :handlingOpinionList="handlingOpinionList"
        :locationLesionList="locationLesionList"
        :moduleInfo="moduleInfo"
        @upDictionaryDate="getTreatment"
        @save="_dialogSave"
      ></universal-info-dialog>
    </div>
  </div>
</template>

<script>
import UniversalInfoDialog from './UniversalInfoDialog.vue'
export default {
  name: 'UniversalBasicInformation',
  components: {
    UniversalInfoDialog
  },

  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    moduleCode: {
      type: String,
      default: () => ''
    },
    allDetail: {
      type: Object,
      default: () => ({})
    },
    formId: {
      type: String,
      require: true
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        if (val) {
          this._getAllBloodVesselDetail()
        }
      }
    },
    moduleCode: {
      handler(val) {
        if (val) {
          this.getTreatment()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      universalTableData: [],
      selectedInfo: null,
      handlingOpinionList: [],
      locationLesionList: [],
      moduleInfo: null,
      tableKey: null
    }
  },
  created() {},
  methods: {
    getTreatment() {
      this.$api.get(`/v1/webconsole/study/pub/this/treatment/${this.moduleCode}`).then((res) => {
        if (res.data && res.data.data) {
          this.handlingOpinionList = res.data.data
        }
      })
      this.$api.get(`/v1/webconsole/study/pub/location/lesion/${this.moduleCode}`).then((res) => {
        if (res.data && res.data.data) {
          console.log('locationLesionList', res.data.data)
          this.locationLesionList = res.data.data
        }
      })
      this.$api.get(`/v1/webconsole/study/module/${this.moduleCode}`).then((res) => {
        if (res.data && res.data.data) {
          console.log('moduleInfo', res.data.data)
          this.moduleInfo = res.data.data
        }
      })
    },
    _getAllBloodVesselDetail() {
      this.$api
        .get(
          `/v1/webconsole/study/universal/body/${this.$route.query.operationId}?moduleCode=${this.moduleCode}`
        )
        .then((res) => {
          if (res.data && res.data.data) {
            this.universalTableData = res.data.data
            this.universalTableData.forEach((element) => {
              element.ids = element.id
            })
          }
        })
    },

    universalInsert() {
      this.$refs.UniversalInfoDialog.Show()
    },
    universalModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条病变信息')
      if (this.selectedInfo) {
        this.$refs.UniversalInfoDialog.Show(this.selectedInfo)
      }
    },
    universalDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条病变信息')
      this.$confirm('是否删除该病变信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.ids) {
          for (let i = 0; i < this.universalTableData.length; i++) {
            const element = this.universalTableData[i]
            if (this.selectedInfo.ids === element.ids) {
              this.universalTableData.splice(i, 1)
              break
            }
          }
        } else {
          return this.$message.warning('当前病变信息不存在')
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.selectedInfo = null
      }
    },
    _rowClick(row) {
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.UniversalInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      if (!val.ids) {
        this.universalTableData.push({
          ...val,
          ids: Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000)
        })
      } else {
        for (let i = 0; i < this.universalTableData.length; i++) {
          const element = this.universalTableData[i]
          if (val.ids === element.ids) {
            this.universalTableData[i] = val
            this.$set(this.universalTableData, i, this.universalTableData[i])
          }
        }
      }
      this.selectedInfo = null
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
</style>
